import React from 'react'
import Container from './container/Container'

const Main = () => {
  return (
    <div>
      <Container />
    </div>
  )
}

export default Main