import React from 'react';
import '../T&C/Terms.css'; 

const PrivacyPolicy = () => {
  return (
    <div className="terms">
      <h1>Privacy Policy</h1>
      <p>
        Welcome to Apexguard.store! This Privacy Policy describes how we collect,
        use, and disclose your personal information when you use our website.
      </p>

      <h2>Information We Collect</h2>
      <p>
        We may collect the following information when you visit our website:
      </p>
      <ul>
        <li>Your IP address</li>
        <li>Your browser type and version</li>
        <li>The pages you visit on our website</li>
        <li>The time and date of your visit</li>
        <li>If you create an account or make a purchase, we may collect additional information such as your name, email address, and billing information.</li>
      </ul>

      <h2>How We Use Your Information</h2>
      <p>
        We may use your information for the following purposes:
      </p>
      <ul>
        <li>To operate and maintain our website</li>
        <li>To improve and personalize your experience on our website</li>
        <li>To send you marketing communications (with your consent)</li>
        <li>To comply with legal and regulatory requirements</li>
      </ul>

      <h2>Disclosure of Your Information</h2>
      <p>
        We may disclose your information to third-party service providers who help
        us operate our website and fulfill your requests. We will not share your
        information with any third-party for marketing purposes without your
        consent.
      </p>

      <h2>Your Choices</h2>
      <p>
        You have choices regarding your personal information:
      </p>
      <ul>
        <li>You can choose to opt out of receiving marketing communications from us.</li>
        <li>You can request to access, update, or delete your personal information.</li>
      </ul>

      <h2>Cookies</h2>
      <p>
        We use cookies on our website. A cookie is a small file that is placed on
        your device by a website when you visit it. You can learn more about
        cookies and how to disable them at [link to cookie information resource].
      </p>

      <h2>Data Security</h2>
      <p>
        We take steps to protect your personal information from unauthorized access,
        disclosure, alteration, or destruction. However, no website is completely
        secure, and we cannot guarantee the security of your information.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you of
        any changes by posting the new Privacy Policy on our website.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us at [email address].
      </p>
    </div>
  );
};

export default PrivacyPolicy;